// NPM
import React from 'react'

// Shared components
import Description from 'src/components/Shared/Description'

const GenericContent = ({ sections }) => {
  const genericContentSection = sections.find(s => s.uuid === "genericContent")

  return (
    <div className="GenericContent">

      <h1 className="GenericContent__title">
        { genericContentSection.title }
      </h1>

      <Description
        className="GenericContent__description"
        description={ genericContentSection.description }
      />
    </div>
  )
  
}

export default GenericContent